import {
  type CSSProperties,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { createPortal } from "react-dom";

import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { useOnClickOutside, useScrollLock } from "usehooks-ts";
import { useKeyPress } from "util/useKeypress";

import { Heading } from "./Heading";

export function Modal({
  children,
  className = "",
  forceOpaqueOverlay = false,
  innerClassName = "",
  onClose,
  size = "2xl",
  style,
}: PropsWithChildren & {
  className?: string;
  forceOpaqueOverlay?: boolean;
  innerClassName?: string;
  onClose?: () => void;
  size?: "sm" | "md" | "lg" | "xl" | "2xl" | "4xl" | "hug-contents";
  style?: CSSProperties;
}) {
  const outsideClickRef = useRef(null);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);
  useOnClickOutside(outsideClickRef, handleClose);

  const escapePress = useKeyPress("Escape");

  useScrollLock({ lockTarget: "#root" });

  useEffect(() => {
    if (escapePress) {
      handleClose();
    }
  }, [escapePress, handleClose]);

  const sizeClass = size === "hug-contents" ? "" : `w-full max-w-${size}`;
  const overlayOpacityClasses = forceOpaqueOverlay
    ? "bg-opacity-100"
    : "bg-opacity-60 dark:bg-opacity-90";

  return createPortal(
    <>
      <div
        aria-modal="true"
        role="dialog"
        className={clsx(
          "fixed inset-0 flex flex-col items-center justify-center z-50 overflow-x-hidden",
          className,
        )}
        style={style}
      >
        <div className={clsx("overflow-y-auto p-4", sizeClass)}>
          <div
            ref={outsideClickRef}
            className={clsx("relative", innerClassName)}
          >
            {children}
          </div>
        </div>
      </div>
      <div
        className={clsx(
          overlayOpacityClasses,
          "bg-gray-900 ",
          "fixed inset-0 z-40",
        )}
      />
    </>,
    document.body,
  );
}

export function ModalHeader({
  children,
  className,
  onClose,
}: PropsWithChildren & { className?: string; onClose?: () => void }) {
  return (
    <div
      className={twMerge(
        "flex items-start justify-between",
        "p-4",
        "bg-gray-700 ",
        "rounded-t-md border border-gray-600",
        className,
      )}
    >
      <Heading level={3}>{children}</Heading>
      {onClose !== undefined && (
        <button
          type="button"
          onClick={() => onClose()}
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
      )}
    </div>
  );
}

ModalHeader.displayName = "ModalHeader";

export function ModalBody({
  children,
  className,
  roundedBottomCorners = true,
}: { className?: string; roundedBottomCorners?: boolean } & PropsWithChildren) {
  return (
    <div
      className={clsx(
        "p-4 md:p-6",
        "border-l border-r border-gray-600",
        roundedBottomCorners && "rounded-b-md border-b",
        "bg-gray-700",
        className,
      )}
    >
      {children}
    </div>
  );
}

ModalBody.displayName = "ModalBody";

export function ModalFooter({
  children,
  className,
}: PropsWithChildren & { className?: string }) {
  return (
    <div
      className={twMerge(
        "flex items-center",
        "py-3 px-6 space-x-2",
        "border border-gray-600 rounded-b",
        className,
      )}
    >
      {children}
    </div>
  );
}

ModalFooter.displayName = "ModalFooter";

Modal.Body = ModalBody;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
