import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { type Conversation, type Message } from "@twilio/conversations";

import { useChatClientManager } from "@components/ChatClientManagerContext";
import { promiseAllNamed } from "@util/promiseAllNamed";
import { getMessages } from "@util/twilioChatHelper";

type ParticipantDataT = { learner: { id: string }; teacher: { id: string } };

/**
 * Is the `userId` a *direct* participant of the Twilio chat?
 * If not, for purposes of this function, they must then be the parent
 */

const isLearnerOrTeacher = (
  userId: string,
  participantData: ParticipantDataT,
) => {
  return [participantData.learner.id, participantData.teacher.id].includes(
    userId,
  );
};

/**
 * Parents can view their childrens chats; in order to do so they need to assume their childrens
 * identity for Twilios AccessToken, so from Twilios perspective the parent *is* the child
 * However messages from parents should show their own avatars etc, so we need to distinguish a
 * childs actual message from a parents message sent from the childrens identity
 * @returns either the teacherId or the studentId (either if you're the student themself or a parent masqurading as them)
 */
export const getTwilioIdentityUserId = (
  userId: string,
  participantData: ParticipantDataT,
): string => {
  return isLearnerOrTeacher(userId, participantData)
    ? userId
    : participantData.learner.id;
};

type ConversationContextType = {
  conversationObj: ActiveConversation;
  loadConversation: (conversationId: string) => Promise<void>;
  twilioConversation: Conversation | undefined;
} & ChatConversationMetadata;

const ConversationContext = createContext<ConversationContextType>(
  null as unknown as ConversationContextType,
);

export function useConversationContext() {
  const context = useContext(ConversationContext);
  if (context === null) {
    // the 2nd camera needs to be able to render without a conversation context
    // and this is an easy away to allow that
    return null;
  }

  return context;
}

export type ActiveConversation = {
  conversationId: string;
  conversationTitle: string;
  isDisabled: boolean;

  /**
   * which userId to use for your twilio authority identity
   * OrgAdmins can instead provide one of their members' TeachingRelationships (in a shared Org) to
   * "impersonate" them for purposes of monitoring their Conversations
   */
  twilioIdentityUserId: string;
};

export function ConversationProvider({
  children,
  conversationObj,
}: PropsWithChildren<{
  conversationObj: ActiveConversation;
}>) {
  const { client } = useChatClientManager(conversationObj.twilioIdentityUserId);
  const [twilioConversation, setTwilioConversation] = useState<Conversation>();

  const onUpdateUnreadCount = useCallback(() => {}, []);

  const { lastMessage, setUnreadMessageCount, unreadMessageCount } =
    useAppChatConversationMetadata(twilioConversation, {
      twilioUserIdentity: conversationObj.twilioIdentityUserId,
      onUpdateUnreadCount,
    });

  const loadConversation = useCallback(
    async (conversationIdToLoad: string) => {
      // eslint-disable-next-line no-console
      console.debug("[chat] loadConversation(): ", conversationIdToLoad);
      if (conversationIdToLoad === "") {
        // eslint-disable-next-line no-console
        console.debug("[chat] conversationIdToLoad not available, skipping");
        return;
      }

      try {
        const conv = await client?.getConversationByUniqueName(
          conversationIdToLoad,
        );
        setTwilioConversation(conv);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(
          `[chat] Failed to load Conversation ${conversationIdToLoad}: `,
          error,
        );

        const RETRY_SECONDS = 15;

        // eslint-disable-next-line no-console
        console.debug(
          `[chat] Will retry loadConversation() in ${RETRY_SECONDS} seconds`,
        );
        setTimeout(() => {
          // eslint-disable-next-line no-console
          console.debug("[chat] Retrying loadConversation()");
          loadConversation(conversationIdToLoad);
        }, RETRY_SECONDS * 1_000);
      }
    },
    [client],
  );

  const { conversationId } = conversationObj;

  useEffect(() => {
    loadConversation(conversationId);
  }, [client, conversationId, loadConversation]);

  const providerValue: ConversationContextType = useMemo(
    () => ({
      conversationObj,
      lastMessage,
      loadConversation,
      setUnreadMessageCount,
      twilioConversation,
      unreadMessageCount,
    }),
    [
      conversationObj,
      lastMessage,
      loadConversation,
      setUnreadMessageCount,
      twilioConversation,
      unreadMessageCount,
    ],
  );

  return (
    <ConversationContext.Provider value={providerValue}>
      {children}
    </ConversationContext.Provider>
  );
}

type ChatConversationMetadata = {
  lastMessage: undefined | Message;
  setUnreadMessageCount: Dispatch<SetStateAction<number>>;
  unreadMessageCount: null | number;
};

export function useAppChatConversationMetadata(
  twilioConversation: undefined | Conversation,
  {
    onUpdateUnreadCount,
    twilioUserIdentity,
  }: {
    onUpdateUnreadCount: (unreadCount: number) => void;
    twilioUserIdentity: null | string;
  },
) {
  const [unreadMessageCount, setUnreadMessageCount] = useState<number | null>(
    null,
  );
  const [lastMessage, setLastMessage] = useState<Message | undefined>(
    undefined,
  );

  const updateConversationMetadata = useCallback(async () => {
    // eslint-disable-next-line no-console
    console.debug("[chat] updateConversationMetadata()");

    if (!twilioConversation || !twilioUserIdentity) {
      return;
    }

    try {
      const { latestUnreadMessageCount, newMessages } = await promiseAllNamed({
        newMessages: getMessages(twilioConversation, 1, 0),
        latestUnreadMessageCount: twilioConversation.getUnreadMessagesCount(),
      });

      const newLastMessage =
        newMessages.items && newMessages.items.length > 0
          ? newMessages.items[0]
          : undefined;
      setLastMessage(newLastMessage);

      const newUnreadCount =
        newLastMessage?.author === twilioUserIdentity
          ? 0
          : latestUnreadMessageCount === null &&
            newLastMessage &&
            twilioConversation.lastReadMessageIndex === null
          ? 1
          : latestUnreadMessageCount || 0;

      setUnreadMessageCount(newUnreadCount);
      onUpdateUnreadCount(newUnreadCount);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("[chat] updateConversationMetadata error", error);
    }
  }, [onUpdateUnreadCount, twilioConversation, twilioUserIdentity]);

  const delayedUpdateConversationMetadata = useCallback(() => {
    const timeout = setTimeout(() => {
      updateConversationMetadata();
    }, 1_000);
    return () => {
      clearTimeout(timeout);
    };
  }, [updateConversationMetadata]);

  useEffect(() => {
    if (twilioConversation) {
      delayedUpdateConversationMetadata();
      twilioConversation.on("updated", updateConversationMetadata);
    }

    return () => {
      if (twilioConversation) {
        twilioConversation.off("updated", updateConversationMetadata);
      }
    };
  }, [
    delayedUpdateConversationMetadata,
    twilioConversation,
    updateConversationMetadata,
  ]);

  return {
    unreadMessageCount,
    setUnreadMessageCount,
    lastMessage,
  };
}
