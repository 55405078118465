import {
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";

import {
  type PopCancelConfirmationModalT,
  useCancelConfirmationModal,
} from "@util/useCancelConfirmationModal";
import {
  type PopConfirmationModalT,
  useUpdateConfirmationModal,
} from "@util/useUpdateConfirmationModal";

type ConfirmationModalContextT = {
  popCancelConfirmationModal: PopCancelConfirmationModalT;
  popConfirmationModal: PopConfirmationModalT;
  dismissConfirmationModal: () => void;
};

const ConfirmationModalContext = createContext<ConfirmationModalContextT>(
  null as unknown as ConfirmationModalContextT,
);

export const useConfirmationModal = () => {
  const context = useContext(ConfirmationModalContext);
  if (context === null) {
    throw new Error(
      "useConfirmationModal() must be used within a <ConfirmationModalProvider>",
    );
  }

  return context;
};

export function ConfirmationModalProvider({ children }: PropsWithChildren) {
  const {
    ConfirmationModal,
    confirmationModalProps,
    popConfirmationModal,
    dismissConfirmationModal,
  } = useUpdateConfirmationModal();

  const {
    CancelConfirmationModal,
    cancelConfirmationModalProps,
    popCancelConfirmationModal,
  } = useCancelConfirmationModal();

  const providerValue = useMemo(
    () => ({
      popConfirmationModal,
      dismissConfirmationModal,
      popCancelConfirmationModal,
    }),
    [popCancelConfirmationModal, popConfirmationModal],
  );

  return (
    <ConfirmationModalContext.Provider value={providerValue}>
      {confirmationModalProps && (
        <ConfirmationModal {...confirmationModalProps} />
      )}
      {cancelConfirmationModalProps && (
        <CancelConfirmationModal {...cancelConfirmationModalProps} />
      )}
      {children}
    </ConfirmationModalContext.Provider>
  );
}
