import { type CustomFlowbiteTheme, theme } from "flowbite-react";
import { twMerge } from "tailwind-merge";

export const cardBaseClassNames = theme.card.root.base;
export const labelBaseClassNames = theme.label.root.base;
export const inputBaseClassNames = theme.textInput.field.input.base;

/**
 * Keep in mind that Tailwind has a "light" mode and a "dark" mode.
 * Separately, Flowbite offers button colors called 'light' and 'dark', which each have definitions that include styles for Tailwind's "light" and "dark" modes.
 * FlowbiteColors 'light' and 'dark' might work fine for individual buttons.
 * We tried also using them within a group of buttons, where 2 or more buttons together acted as a toggle, and we set the "active" button to "light" color and inactive buttons to "dark".
 * This worked fine in Tailwind "light" mode but felt backwards in Tailwind "dark" mode.
 * So, whenever you're using Button.Group, you should probably set the "color" prop of each button via `getColorForButtonGroup` below, which uses this enum.
 */
enum ButtonGroupColor {
  DEFAULT = "default",
  /**
   * Use this for the one button within the "toggle" group that the user has chosen.
   */
  HIGHLIGHTED = "highlighted",
}

/**
 * https://lessonhomeworkspace.slack.com/archives/C03Q6PE3C2K/p1697232887625849?thread_ts=1697232139.361669&cid=C03Q6PE3C2K
 */
export function getColorForButtonGroup(isMatch: boolean): ButtonGroupColor {
  return isMatch ? ButtonGroupColor.HIGHLIGHTED : ButtonGroupColor.DEFAULT;
}

export const customFlowbiteTheme: CustomFlowbiteTheme = {
  tabs: {
    tablist: {
      tabitem: {
        base: "flex items-center justify-center disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500",
        variant: {
          underline: {
            base: "border-b-2 py-1 px-4 text-xs flex-1 rounded-t-none focus:ring-none",
            active: {
              on: "border-forteBlue text-forteBlue",
              off: "border-b-2 border-transparent text-[#7C8CA8] hover:border-[#7C8CA8] focus:ring-none",
            },
          },
        },
      },
    },
  },
  label: {
    root: {
      base: `${labelBaseClassNames} text-sm font-medium`,
    },
  },
  textInput: {
    field: {
      input: {
        base: `${inputBaseClassNames} border-none text-white font-normal`,
        colors: {
          gray: "bg-forteDeepBlue-60",
        },
      },
    },
  },
  badge: {
    root: {
      color: {
        info: twMerge(
          "bg-forteBlue text-gray-200",
          "dark:bg-forteBlue dark:text-gray-800",
        ),
      },
    },
  },
  alert: {
    color: {
      info: twMerge(
        "bg-forteBlue text-gray-200 border-gray-800",
        "dark:bg-forteBlue dark:text-gray-800",
      ),
    },
  },
  button: {
    color: {
      warning: twMerge("bg-yellow-400 text-white", "hover:bg-yellow-500"),
      warningInverted: twMerge(
        "border",
        "border-yellow-700 text-yellow-700",
        "hover:bg-yellow-100",
      ),
      neon: twMerge(
        "dark:bg-forteNeon dark:text-gray-900",
        "text-white bg-forteBlue",
        "dark:hover:text-gray-800 dark:hover:bg-opacity-90",
      ),
      neonInverted: twMerge(
        "bg-gray-800 text-forteNeon hover:bg-forteNeon hover:text-gray-800",
        "dark:bg-gray-800 dark:text-forteNeon",
      ),
      blue: twMerge(
        "dark:bg-forteBlue dark:text-white",
        "bg-forteBlue text-white",
      ),
      darknavy: twMerge(
        "dark:bg-forteTertiaryBlue dark:text-forteCharcoal-10 dark:active:hover:bg-forteTertiaryBlue-80",
        "bg-forteTertiaryBlue text-forteCharcoal-10 hover:bg-forteTertiaryBlue-80",
      ),
      red: twMerge(
        "dark:bg-red-500 dark:text-forteCharcoal-10",
        "bg-red-500 text-forteCharcoal-10",
      ),
      [ButtonGroupColor.DEFAULT]: twMerge(
        "focus:outline-none focus:ring-4",
        "border",
        "text-gray-900 bg-white enabled:hover:bg-gray-100 focus:ring-forteNeon border-gray-300",
        "dark:text-white dark:bg-gray-800 dark:enabled:hover:bg-gray-700 dark:focus:ring-gray-800 dark:border-gray-700",
      ),
      [ButtonGroupColor.HIGHLIGHTED]: twMerge(
        "focus:ring-4 focus:outline-none border ",
        "bg-gray-800 text-white border-transparent enabled:hover:bg-gray-900 focus:ring-gray-300",
        "dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:enabled:hover:bg-gray-700 dark:enabled:hover:border-gray-700 dark:focus:ring-gray-700",
      ),
    },
  },
  card: {
    root: {
      base: `rounded-lg bg-forteDeepBlue-80`,
      children:
        "flex h-full flex-col justify-center gap-4 md:px-6 md:py-6 px-3 py-4",
    },
  },
};
