import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  AppBar,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Media, Message } from "@twilio/conversations";
import clsx from "clsx";
import { MessageSquare } from "lucide-react";

import { ChatMessages } from "@components/Chat/ChatMessages";
import { useConversationContext } from "@components/Chat/ConversationContext";
import DragAndDrop from "@components/Chat/DragAndDrop";
import { Icon } from "@riffs/Icon";

import { FileToSend } from "../FileToSend";

export const ILEChat = ({
  shareFile,
  chatScroll,
  loadPreviousMessage,
  maybeRenderDate,
  downloadingFileIds,
  downloadHandler,
  uploadingMessageIndexes,
  handleSendMessage,
  filesToSend,
  setFilesToSend,
  scrollToBottom,
  message,
  setMessage,
  messageList,
  loading,
  isChatPanelOpen,
  onClose,
  className,
}: {
  shareFile: (files: FileList) => Promise<void>;
  chatScroll: React.MutableRefObject<HTMLDivElement | null>;
  loadPreviousMessage: (e: any) => Promise<void>;
  maybeRenderDate: (date: Date | null) => JSX.Element | null;
  downloadingFileIds: string[];
  downloadHandler: (media: Media) => Promise<void>;
  uploadingMessageIndexes: number[];
  handleSendMessage: (event: FormEvent | KeyboardEvent) => Promise<void>;
  filesToSend: File[];
  setFilesToSend: Dispatch<SetStateAction<File[]>>;
  scrollToBottom: () => void;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  messageList: Message[];
  loading: boolean;
  isChatPanelOpen: boolean;
  onClose: () => void;
  className?: string;
}) => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const conversationContext = useConversationContext();
  if (!conversationContext) {
    throw new Error("<ConversationProvider> required");
  }

  const onResize = useCallback(() => {
    setScreenHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [screenHeight]);

  useEffect(() => {
    if (isChatPanelOpen) {
      scrollToBottom();
    }
  }, [isChatPanelOpen]);

  return (
    <div className={clsx(className)}>
      <div
        className={clsx(
          "fixed top-0 bottom-0 right-0",
          "w-96 h-full",
          "m-0 z-50",
          "overflow-hidden shadow-slate-400 bg-white",
          "flex flex-col max-w-full",
          "transition-transform",
          isChatPanelOpen ? "translate-x-0" : "translate-x-full",
        )}
      >
        <AppBar
          position="absolute"
          className="!top-[unset] !bottom-0 !right-0 !bg-[#3a3a3a]"
          elevation={1}
        >
          <div className="flex flex-1 flex-row">
            <div className="flex flex-1 items-center">
              <IconButton className="" color="inherit" onClick={onClose}>
                <MessageSquare size={24} className="text-[#e9e9e9]" />
              </IconButton>
              <Typography className={clsx("mx-8 text-16 text-[#e9e9e9]")}>
                Chat
              </Typography>
            </div>
            <div className="flex px-4">
              <IconButton onClick={onClose} color="inherit">
                <Icon.Cancel />
              </IconButton>
            </div>
          </div>
        </AppBar>

        <DragAndDrop handleDrop={shareFile}>
          <Paper className="flex flex-1 flex-row min-h-px">
            <Paper
              elevation={3}
              style={{ height: `${screenHeight}px` }}
              className={clsx(
                "bg-[#e3e8f8] w-96",
                "pb-16 flex flex-1 flex-col z-10 relative",
              )}
            >
              <div
                id="bubbleContainer"
                ref={chatScroll}
                onScroll={loadPreviousMessage}
                className="flex flex-col overflow-y-auto h-full"
              >
                <ChatMessages
                  isILE={true}
                  loading={loading}
                  messageList={messageList}
                  maybeRenderDate={maybeRenderDate}
                  downloadingFileIds={downloadingFileIds}
                  downloadHandler={downloadHandler}
                  uploadingMessageIndexes={uploadingMessageIndexes}
                />
              </div>
              <div>
                <form onSubmit={handleSendMessage}>
                  <div className="px-2 relative bottom-0 left-0 right-0">
                    <Paper
                      className={clsx(
                        "rounded-3xl",
                        "block items-center relative",
                      )}
                    >
                      {filesToSend.length > 0 && (
                        <div
                          className={clsx("overflow-auto max-h-[27vh]", "pt-3")}
                        >
                          {filesToSend.map((obj, i) => {
                            return (
                              <FileToSend
                                key={i}
                                file={obj}
                                onRemove={() => {
                                  setFilesToSend(
                                    filesToSend.filter((file) => file !== obj),
                                  );
                                  scrollToBottom();
                                }}
                              />
                            );
                          })}
                        </div>
                      )}
                      <div className="flex box-border">
                        <TextField
                          className={clsx("w-[90%] flex-1")}
                          multiline
                          maxRows={5}
                          InputProps={{
                            disableUnderline: true,

                            classes: {
                              input: "!shadow-none !ring-0 !ring-transparent",
                              root: clsx(
                                "text-black",
                                "flex flex-grow flex-shrink-0 mx-4 my-2",
                              ),
                            },
                            placeholder: "Type your message",
                          }}
                          InputLabelProps={{
                            shrink: false,
                          }}
                          onChange={(e) => setMessage(e.currentTarget.value)}
                          value={message}
                          onKeyDown={(event) => {
                            if (event.key === "Enter" && !event.shiftKey) {
                              event.preventDefault();
                              handleSendMessage(event);
                            }
                          }}
                        />
                        <label
                          className="MuiButtonBase-root MuiIconButton-root absolute ltr:right-0 rtl:left-0 p-2"
                          htmlFor="button-file"
                        >
                          <input
                            className="hidden"
                            id="button-file"
                            type="file"
                            onChange={(event) => {
                              if (event.target.files) {
                                shareFile(event.target.files);
                              }
                            }}
                            multiple
                          />
                          <Icon.Attachment width={24} height={24} />
                        </label>
                        <IconButton
                          className="absolute ltr:right-0 rtl:left-0 p-2"
                          type="submit"
                        >
                          <Icon.SendDiagonal width={24} height={24} />
                        </IconButton>
                      </div>
                    </Paper>
                  </div>
                </form>
              </div>
            </Paper>
          </Paper>
        </DragAndDrop>
      </div>
    </div>
  );
};
