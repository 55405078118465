import React, {
  type PropsWithChildren,
  type ReactEventHandler,
  useState,
} from "react";
import {
  type FieldError,
  type FieldErrorsImpl,
  type Merge,
} from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { type ApolloError } from "@apollo/client";
import {
  FileInput as FlowBiteFileInput,
  Checkbox as FlowbiteCheckbox,
  HelperText as FlowbiteHelperText,
  Label as FlowbiteLabel,
  Radio as FlowbiteRadio,
  Select as FlowbiteSelect,
  TextInput as FlowbiteTextInput,
  Textarea as FlowbiteTextarea,
} from "flowbite-react";
import { inputBaseClassNames } from "styles/CustomFlowbiteTheme";
import { twMerge } from "tailwind-merge";

import { Loading } from "./Loading";
import { StyledText } from "./StyledText";

/**
 * Form elements like Input, Select, and Textarea have a consistent rhythm for (Label (above); Field; Error (below))
 *
 * `WRAPPER_SPACING_CLASSES` keeps this consistent and is tuned to work with `REMOVE_MARGIN_AMOUNT` above so the fields won't jump around when there are errors
 *
 * Adding an error message to a field means a whole new element is added to the stack but the whole component gets negative bottom margin to offset
 * the new element's height
 *
 * `x` needs to be the same in `gap-[x]` and `my-[x]` because the negative bottom margin added by `REMOVE_MARGIN_AMOUNT` knocks out the bottom part of `my-[x]`,
 * but the added gap between the field and the error messages offsets it (so the fields don't jump around)
 *
 * TL;DR: This is a fine tuned math equation, please be very careful if you change it
 */
export const WRAPPER_SPACING_CLASSES = twMerge(
  "gap-1", // 1 unit gap between elements (label, input field, error message)
  "mt-1", // 1 unit margin on top and bottom of the whole component
  "mb-3",
);

export type InputProps = {
  /**
   * To pass accepted input type like gif,pdf
   */
  accept?: string;
  autoComplete?: string;
  className?: string;
  /**
   * Color to pass to Flowbite
   */
  color?: string;
  /**
   * To set the field's initial value
   */
  defaultValue?: string;
  /**
   * To make the `<input>` element disabled
   */
  disabled?: boolean;
  /**
   * Error message from react-hook-form
   */
  errorMessage?:
    | string
    | boolean
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>;
  /**
   * ID to use on `<input>` element
   */
  id?: string;
  /**
   * Contents of `<label>` element
   */
  labelText?: string;
  /**
   * onChange for Input
   */
  onChange?: ReactEventHandler<HTMLInputElement>;
  /**
   * For regular <input> oninput= field
   */
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: ReactEventHandler<HTMLDivElement>;
  /**
   * Placeholder for the `<input>` element
   */
  placeholder?: string;
  /**
   * To make the `<input>` element required
   */
  required?: boolean;
  /**
   * For using Flowbite's `sizing=` prop on Input
   */
  sizing?: "sm" | "md" | "lg";
  /**
   * Type for the `<input>` element; defaults to `"text"`
   */
  type?: string;
  /**
   * To set/grab the value of the `<input>` element
   */
  value?: string;
  wrapperClassName?: string;
};

export const InputStandardClasses = twMerge(
  "block w-full rounded-lg p-2.5 text-sm",
  "border",
  "disabled:cursor-not-allowed disabled:opacity-50",

  // colors
  "bg-gray-50 dark:bg-gray-700",
  "text-gray-900 dark:text-white",
  "border-gray-300 focus:border-blue-500 dark:border-gray-600 dark:focus:border-blue-500",
  "focus:ring-blue-500 dark:focus:ring-blue-500",
  "dark:placeholder-gray-400",
);

/**
 * Input
 *
 * A text input field for forms.
 *
 * Renders a HTML `<input>` element with the correct styling/features/etc.
 */
export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      errorMessage,
      id,
      labelText,
      placeholder,
      required = false,
      sizing = "md",
      type = "text",
      value,
      wrapperClassName,
      ...props
    }: InputProps,
    ref,
  ) => {
    /**
     * Classes to apply to the outside wrapper
     */
    const WRAPPER_CLASSES = twMerge(
      WRAPPER_SPACING_CLASSES,
      "flex flex-col w-full",
      wrapperClassName,
    );

    return (
      <div className={WRAPPER_CLASSES}>
        {labelText && <FlowbiteLabel htmlFor={id}>{labelText}</FlowbiteLabel>}
        <FlowbiteTextInput
          {...props}
          ref={ref}
          type={type}
          id={id}
          sizing={sizing}
          className={className}
          placeholder={placeholder}
          required={required}
          value={value}
        />
        {typeof errorMessage === "string" && (
          <FlowbiteHelperText color="failure" className="mt-0">
            {errorMessage}
          </FlowbiteHelperText>
        )}
      </div>
    );
  },
);
Input.displayName = "Input";

export type PasswordInputProps = {
  // To pass accepeted input type like gif,pdf
  accept?: string;
  /**
   * Color to pass to Flowbite
   */
  autoComplete?: string;
  color?: string;
  /**
   * To set the field's initial value
   */
  defaultValue?: string;
  /**
   * To make the `<input>` element disabled
   */
  disabled?: boolean;
  /**
   * Error message from react-hook-form
   */
  errorMessage?: string | boolean;
  // to pass icon
  icon?: React.FC<React.SVGProps<SVGSVGElement>> | undefined;
  /**
   * ID to use on `<input>` element
   */
  id: string;
  /**
   * Contents of `<label>` element
   */
  labelText?: string;
  /**
   * onChange for Input
   */
  onChange?: ReactEventHandler<HTMLInputElement>;
  /**
   * Placeholder for the `<input>` element
   */
  placeholder?: string;
  /**
   * To make the `<input>` element required
   */
  required?: boolean;
  /**
   * Initial state for "show password"
   */
  showPassword?: boolean;
  /**
   * Type for the `<input>` element; defaults to `"text"`
   */
  type?: string;
  /**
   * To set/grab the value of the `<input>` element
   */
  value?: string;
};

/**
 * Input
 *
 * A text input field for forms
 *
 * Renders a HTML `<input>` element with the correct styling/features/etc.
 */
export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  PasswordInputProps
>(
  (
    {
      autoComplete,
      errorMessage,
      icon,
      id,
      labelText,
      placeholder,
      required = false,
      showPassword = false,
      type = "text",
      value,
      ...props
    }: PasswordInputProps,
    ref,
  ) => {
    const [isShowPassword, setShowPassword] = useState(showPassword);

    /**
     * Classes to apply to the outside wrapper
     */
    const WRAPPER_CLASSES = twMerge(
      WRAPPER_SPACING_CLASSES,
      "flex flex-col relative w-full",
    );

    return (
      <div className={WRAPPER_CLASSES}>
        {labelText && <FlowbiteLabel htmlFor={id}>{labelText}</FlowbiteLabel>}

        <div className="flex flex-row relative">
          <input
            className={`${inputBaseClassNames} border-none text-white font-normal bg-forteDeepBlue-60 rounded-lg pl-2.5 py-2.5 pr-10`}
            {...props}
            ref={ref}
            type={isShowPassword ? "text" : "password"}
            id={id}
            placeholder={placeholder}
            required={required}
            value={value}
            autoCapitalize="none"
            autoComplete={autoComplete}
          />

          <div
            onClick={() => setShowPassword(!isShowPassword)}
            className="absolute right-0 w-12 h-full flex items-center justify-center cursor-pointer"
          >
            <StyledText size="xl">
              {isShowPassword ? <FaEye /> : <FaEyeSlash />}
            </StyledText>
          </div>
        </div>

        {errorMessage && (
          <FlowbiteHelperText color="failure" className="mt-0">
            {errorMessage}
          </FlowbiteHelperText>
        )}
      </div>
    );
  },
);
PasswordInput.displayName = "PasswordInput";

export type FileInputProps = {
  // To pass accepeted input type like gif,pdf
  accept?: string;
  /**
   * Color to pass to Flowbite
   */
  color?: string;
  /**
   * To set the field's initial value
   */
  defaultValue?: string;
  /**
   * To make the `<input>` element disabled
   */
  disabled?: boolean;
  /**
   * Error message from react-hook-form
   */
  errorMessage?: string;
  /**
   * ID to use on `<input>` element
   */
  id: string;
  /**
   * Contents of `<label>` element
   */
  labelText?: string;
  /**
   * onChange for Input
   */
  onChange?: ReactEventHandler<HTMLInputElement>;
  /**
   * Placeholder for the `<input>` element
   */
  placeholder?: string;
  /**
   * To make the `<input>` element required
   */
  required?: boolean;
  /**
   * Type for the `<input>` element; defaults to `"text"`
   */
  type?: string;
  /**
   * To set/grab the value of the `<input>` element
   */
  value?: string;
};

/**
 * Input
 *
 * A file input field for forms
 *
 * Renders a HTML `<input>` element with the correct styling/features/etc.
 */
export const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      color = "gray",
      errorMessage,
      id,
      labelText,
      placeholder,
      required = false,
      type = "text",
      value,
      ...props
    }: FileInputProps,
    ref,
  ) => {
    /**
     * Classes to apply to the outside wrapper
     */
    const WRAPPER_CLASSES = twMerge(WRAPPER_SPACING_CLASSES, "flex flex-col");

    return (
      <div className={WRAPPER_CLASSES}>
        {labelText && (
          <FlowbiteLabel htmlFor={id} color={errorMessage ? "failure" : color}>
            {labelText}
          </FlowbiteLabel>
        )}
        <FlowBiteFileInput
          {...props}
          ref={ref}
          id={id}
          color={errorMessage ? "failure" : color}
          placeholder={placeholder}
          required={required}
          value={value}
        />
        {errorMessage && (
          <FlowbiteHelperText color="failure" className="mt-0">
            {errorMessage}
          </FlowbiteHelperText>
        )}
      </div>
    );
  },
);
FileInput.displayName = "FileInput";

export type SelectProps = {
  children: React.ReactNode;
  // TODO: Update to `sizing` since this is what Flowbite calls it
  className?: string;
  color?: string;
  defaultValue?: string;
  disabled?: boolean;
  errorMessage?: string;
  hidden?: boolean;
  id: string;
  labelText?: string;
  multiple?: boolean;
  onChange?: ReactEventHandler<HTMLSelectElement>;
  onSelect?: ReactEventHandler<HTMLSelectElement>;
  placeholder?: string;
  required?: boolean;
  size?: string;
  value?: string;
};

/**
 * Select
 *
 * A dropdown select field for forms
 *
 * Renders a HTML `<select>` and `<option>`s elements with the correct styling/features/etc.
 *
 * Pass in the `<option>` fields as children with their "value" props set already
 */
export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      children,
      errorMessage,
      hidden,
      id,
      labelText,
      onChange,
      onSelect,
      placeholder,
      required = false,
      size,
      value,
      ...props
    }: SelectProps,
    ref,
  ) => {
    const WRAPPER_CLASSES = twMerge(
      WRAPPER_SPACING_CLASSES,
      hidden && "hidden",
      "flex flex-col",
    );

    return (
      <div className={WRAPPER_CLASSES}>
        {labelText && <FlowbiteLabel htmlFor={id}>{labelText}</FlowbiteLabel>}
        <FlowbiteSelect
          sizing={size} // TODO: Update to `sizing` since this is what Flowbite calls it
          id={id}
          ref={ref}
          value={value}
          onChange={onChange}
          onSelect={onSelect}
          {...props}
        >
          {children}
        </FlowbiteSelect>
        {errorMessage && (
          <FlowbiteHelperText color="failure" className="mt-0">
            {errorMessage}
          </FlowbiteHelperText>
        )}
      </div>
    );
  },
);
Select.displayName = "Select";

export type TextareaProps = {
  color?: string;
  defaultValue?: string;
  disabled?: boolean;
  errorMessage?: string;
  id: string;
  labelText?: string;
  maxLength?: number;
  onChange?: ReactEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  required?: boolean;
  resizable?: boolean;
  rows?: number;
  value?: string;
};
/**
 * Textarea
 *
 * A textarea field for forms
 *
 * Renders a HTML `<textarea>` element with the correct styling/features/etc.
 */
export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      errorMessage,
      id,
      labelText,
      onChange,
      placeholder,
      required = false,
      resizable = true,
      rows = 5,
      value,
      ...props
    }: TextareaProps,
    ref,
  ) => {
    /**
     * Classes to apply to the outside wrapper
     */
    const WRAPPER_CLASSES = twMerge(WRAPPER_SPACING_CLASSES, "flex flex-col");

    return (
      <div className={WRAPPER_CLASSES}>
        {labelText && <FlowbiteLabel htmlFor={id}>{labelText}</FlowbiteLabel>}
        <FlowbiteTextarea
          id={id}
          ref={ref}
          value={value}
          rows={rows}
          placeholder={placeholder}
          onChange={onChange}
          className={twMerge(resizable === false && "resize-none")}
          {...props}
        />
        {errorMessage && (
          <FlowbiteHelperText color="failure" className="mt-0">
            {errorMessage}
          </FlowbiteHelperText>
        )}
      </div>
    );
  },
);
Textarea.displayName = "Textarea";

export type CheckboxProps = {
  checked?: boolean;
  children?: React.ReactNode;
  className?: string;
  color?: string;
  defaultChecked?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  errorMessage?: string;
  id: string;
  labelText?: string;
  onChange?: ReactEventHandler<HTMLInputElement>;
  required?: boolean;
  value?: string;
};

/**
 * Checkbox
 *
 * A checkbox field for forms
 *
 * Renders a HTML `<input type="checkbox">` element with the correct styling/features/etc.
 */
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      children,
      className,
      color = "gray",
      errorMessage,
      id,
      labelText,
      ...props
    }: CheckboxProps,
    ref,
  ) => {
    const WRAPPER_CLASSES = twMerge(
      "flex",
      // ! Not WRAPPER_SPACING_CLASSES here because this renders inline with its label

      "gap-2", // Defines the horizontal space between the checkbox and its label

      className,
    );

    return (
      <div className={WRAPPER_CLASSES}>
        {/* Checkbox target */}
        <FlowbiteCheckbox id={id} ref={ref} {...props} />

        {/* Container for label and error message */}
        <div className="flex flex-col">
          <FlowbiteLabel htmlFor={id}>{labelText || children}</FlowbiteLabel>
          {errorMessage && (
            <FlowbiteHelperText color="failure" className="mt-0">
              {errorMessage}
            </FlowbiteHelperText>
          )}
        </div>
      </div>
    );
  },
);
Checkbox.displayName = "Checkbox";

export type RadioGroupProps = {
  children: React.ReactNode;
  errorMessage?: string;
  id: string;
  labelText?: string;
  required?: boolean;
};

/**
 * RadioGroup
 *
 * A wrapper for multiple Radio components that should behave together
 *
 * Returns a Label, fieldset, and HelperText for errors
 */
export const RadioGroup = React.forwardRef<
  HTMLFieldSetElement,
  RadioGroupProps
>(({ children, errorMessage, id, labelText }: RadioGroupProps, ref) => {
  /**
   * Classes to apply to the outside wrapper
   */
  const WRAPPER_CLASSES = twMerge(
    WRAPPER_SPACING_CLASSES,
    "flex flex-col",
    "gap-3", // Keep a spacing between label, radio inputs, and errors
  );

  return (
    <div className={WRAPPER_CLASSES}>
      {labelText && (
        <FlowbiteLabel htmlFor={id} color={errorMessage ? "failure" : "gray"}>
          {labelText}
        </FlowbiteLabel>
      )}
      <fieldset id={id} className={FIELDSET_CLASSES} ref={ref}>
        {children}
      </fieldset>

      {errorMessage && (
        <FlowbiteHelperText color="failure" className="mt-0">
          {errorMessage}
        </FlowbiteHelperText>
      )}
    </div>
  );
});
RadioGroup.displayName = "RadioGroup";

export type RadioProps = {
  checked?: boolean;
  children?: React.ReactNode;
  className?: string;
  color?: string;
  defaultChecked?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  errorMessage?: string;
  id: string;
  labelText?: string;
  /**
   * Use `name=` prop to match a fieldset's `id=` prop so a group of Radio buttons will work together
   */
  name?: string;
  onChange?: ReactEventHandler<HTMLInputElement>;
  required?: boolean;
  value?: string;
};

/**
 * Radio
 *
 * A radio field for forms
 *
 * Renders a HTML `<input type="radio">` element with the correct styling/features/etc.
 */
export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      children,
      className,
      color = "gray",
      errorMessage,
      id,
      labelText,
      name,
      ...props
    }: RadioProps,
    ref,
  ) => {
    const WRAPPER_CLASSES = twMerge(
      "flex",
      // ! Not WRAPPER_SPACING_CLASSES here because this renders inline with its label

      "gap-2", // Defines the horizontal space between the radio input and its label

      className,
    );

    return (
      <div className={WRAPPER_CLASSES}>
        {/* Radio target */}
        <FlowbiteRadio
          id={id}
          name={name}
          ref={ref}
          color={errorMessage ? "failure" : color}
          {...props}
        />

        {/* Container for label and error message */}
        <div className="flex flex-col">
          <FlowbiteLabel color={errorMessage ? "failure" : color} htmlFor={id}>
            {labelText || children}
          </FlowbiteLabel>
          {errorMessage && (
            <FlowbiteHelperText color="failure" className="mt-0">
              {errorMessage}
            </FlowbiteHelperText>
          )}
        </div>
      </div>
    );
  },
);
Radio.displayName = "Radio";

/**
 * FormResults
 *
 * Helper to show loading/success/error below form submit
 *
 * Pass in the mutation hook's `data`, `error`, and `loading` props and it renders a loader/saved/error message when the form is done.
 */
export const FormResults: React.FC<{
  error: null | undefined | ApolloError | { message?: string };
  loading?: null | boolean;
  loadingMessage?: string;
  success: boolean;
  successMessage?: string;
}> = ({
  error,
  loading,
  loadingMessage = "Loading...",
  success,
  successMessage = "Saved successfully.",
}) => {
  return (
    <div
      className={twMerge(
        "max-h-10", // This matches the height of a regular-sized Button since these are used inline with a Button
        "overflow-y-auto", // Make the message scroll if the message gets too big (hoping this doesn't happen but I want to avoid it affecting the outer layout if it does)
      )}
    >
      {loading && (
        <div className="text-sm">
          <Loading text={loadingMessage} />
        </div>
      )}
      {success && (
        <FlowbiteLabel color="success">{successMessage}</FlowbiteLabel>
      )}
      {error?.message && (
        <FlowbiteLabel color="failure">
          <p className="text-xs leading-tight">
            <span data-cy="form-results-error">{error.message}</span>
          </p>
        </FlowbiteLabel>
      )}
    </div>
  );
};

/**
 * Helper wrapper for making two fields show side-by-side
 */
export const SideBySide = ({ children }: PropsWithChildren) => (
  <div className={twMerge("grid", "grid-cols-1", "md:grid-cols-2", "md:gap-6")}>
    {children}
  </div>
);

/**
 * Helper wrapper for making a field full-width
 */
export const FullWidth = ({ children }: PropsWithChildren) => (
  <div className={twMerge("grid", "grid-cols-1")}>{children}</div>
);

/**
 * Helper wrapper for Submit button and FormResults
 */
export const SubmitWrapper = ({ children }: PropsWithChildren) => (
  <div className={twMerge("flex gap-6 items-center")}>{children}</div>
);

/**
 * Generic error message when a field is required
 */
export const REQUIRED_ERROR_MESSAGE = "This field is required."; // TODO: Rename to `ERROR_MESSAGE_REQUIRED` to match format of other errors

/**
 * Generic error message when an email field is invalid
 */
export const ERROR_MESSAGE_INVALID_EMAIL =
  "Please enter a valid email address.";

/**
 * Regular expression for use with email validation
 *
 * Usage:
 *
 * ```
 * <Input
 *   {...register("email", {
 *     pattern: {
 *       value: VALIDATE_PATTERN_EMAIL_ADDRESS,
 *       message: ERROR_MESSAGE_INVALID_EMAIL,
 *     },
 *   })} />
 * ```
 */
export const VALIDATE_PATTERN_EMAIL_ADDRESS =
  /^[\w.!#$%&'*+/=?^`{|}~-]+@[a-zA-Z\d-]+(?:\.[a-zA-Z\d-]+)*$/u;

/**
 * To give fields on the form a consistent spacing (intended to be added to `<form>` element)
 */
export const FORM_LAYOUT_CLASSES = "flex flex-col gap-4";

/**
 * To give fields in a fieldset (namely, a group of radio buttons) a consistent spacing
 */
export const FIELDSET_CLASSES = "flex flex-col gap-1";

export { HelperText, Label } from "flowbite-react";
