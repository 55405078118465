import { useState } from "react";
import { FaXmark } from "react-icons/fa6";

import { Button } from "flowbite-react";
import { twMerge } from "tailwind-merge";

import { DrawerModal } from "@riffs/DrawerModal";
import { Heading } from "@riffs/Heading";

import { Chat } from "../Chat";
import { ConversationProvider } from "../ConversationContext";
import { useAppChat } from "./AppChatProvider";
import { ChannelList } from "./ChannelList";

export function AppChatDrawer() {
  const [forceFullscreenChat, setForceFullscreenChat] = useState(false);

  const {
    activeConversation,
    closeActiveConversation,
    isChatDrawerOpen,
    setIsChatDrawerOpen,
  } = useAppChat();

  const handleBackButtonClick = () => {
    if (isChatDrawerOpen && !activeConversation) {
      setIsChatDrawerOpen(false);
    }

    if (isChatDrawerOpen && activeConversation) {
      closeActiveConversation();
    }
  };

  return (
    <DrawerModal
      className="max-w-md"
      onClose={() => setIsChatDrawerOpen(false)}
      isDrawerOpen={isChatDrawerOpen}
    >
      <div className="h-screen flex flex-col">
        <div
          className={twMerge(
            "flex w-full items-center justify-between border-b border-gray-700 transition-shadow",
            Boolean(activeConversation) && "shadow-md",
          )}
        >
          <Heading
            level={6}
            className={twMerge(
              "px-4 overflow-hidden whitespace-nowrap text-ellipsis",
            )}
          >
            {activeConversation?.conversationTitle}
          </Heading>

          <Button
            color="gray"
            className="m-2"
            size="sm"
            onClick={handleBackButtonClick}
          >
            <span className="flex flex-row items-center gap-2">
              Close
              <FaXmark />
            </span>
          </Button>
        </div>
        {!activeConversation && <ChannelList />}
        {activeConversation && (
          <ConversationProvider
            key={activeConversation.conversationId}
            conversationObj={activeConversation}
          >
            <Chat
              isILE={false}
              onClose={() => closeActiveConversation()}
              conversationTitle={activeConversation.conversationTitle}
              forceFullscreenChat={forceFullscreenChat}
              toggleForceFullscreenChat={() =>
                setForceFullscreenChat(!forceFullscreenChat)
              }
            />
          </ConversationProvider>
        )}
      </div>
    </DrawerModal>
  );
}
