import { useCallback } from "react";

import { type Conversation } from "@twilio/conversations";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { useMaybeViewerContext } from "@components/MaybeViewerProvider";
import { useConversationId } from "@components/TeachingRelationshipConversationId";
import { Avatar } from "@riffs/Avatar";
import { StyledText } from "@riffs/StyledText";

import { renderTime } from "../ChatBubble";
import {
  getTwilioIdentityUserId,
  useAppChatConversationMetadata,
} from "../ConversationContext";
import { useAppChat } from "./AppChatProvider";

import { type ConversationDetailsFragment } from "@graphql";

export const ConversationChannel = ({
  conversationTitle,
  teachingRelationship,
  twilioConversation,
}: {
  conversationTitle: string;
  teachingRelationship: ConversationDetailsFragment;
  twilioConversation: undefined | Conversation;
}) => {
  const { viewer } = useMaybeViewerContext();
  const { getOrCreateConversationId } = useConversationId();
  const { handleUnreadCountUpdate, openChatWithId } = useAppChat();

  const onUpdateUnreadCount = useCallback(
    (unreadCount: number) =>
      handleUnreadCountUpdate(teachingRelationship.id, unreadCount),
    [handleUnreadCountUpdate],
  );
  const { lastMessage, setUnreadMessageCount, unreadMessageCount } =
    useAppChatConversationMetadata(twilioConversation, {
      twilioUserIdentity:
        (viewer && getTwilioIdentityUserId(viewer.id, teachingRelationship)) ||
        null,
      onUpdateUnreadCount,
    });

  const openChatWidget = async () => {
    await getOrCreateConversationId(teachingRelationship.id);

    openChatWithId(teachingRelationship.id);

    if (twilioConversation) {
      await twilioConversation.setAllMessagesRead();
      setUnreadMessageCount(0);
    }
  };

  if (!viewer) {
    return null;
  }

  return (
    <button
      type="button"
      className={clsx("w-full hover:bg-forteDeepBlue-80")}
      onClick={openChatWidget}
    >
      <div className="py-4 px-4 flex justify-between w-full border-gray-300 dark:border-gray-700 border-b">
        <div className="flex gap-2">
          <Avatar />
          <div className="flex flex-col items-start">
            <StyledText size="sm">{conversationTitle}</StyledText>
            <StyledText
              secondary
              size="sm"
              className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[200px]"
            >
              {lastMessage &&
                (lastMessage.body ? lastMessage.body : "attachment")}
            </StyledText>
          </div>
        </div>

        <div className="flex flex-col items-end gap-1">
          <StyledText secondary size="sm">
            {renderTime(lastMessage?.dateCreated ?? null)}
          </StyledText>

          {Boolean(unreadMessageCount) && (
            <div
              className={twMerge(
                "flex justify-center items-center",
                "h-5 w-5",
                "bg-forteBlue dark:bg-forteNeon rounded-full",
                "text-sm font-bold text-white dark:text-gray-800",
              )}
            >
              {unreadMessageCount}
            </div>
          )}
        </div>
      </div>
    </button>
  );
};
