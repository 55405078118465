import { useState } from "react";

import { Button } from "flowbite-react";

import { Modal } from "@riffs/Modal";

export type PopConfirmationModalT = (arg: {
  onConfirm: () => void;
  onCancel?: () => void;
  title?: ConfirmationModalPropsT["title"];
  confirmText?: ConfirmationModalPropsT["confirmText"];
  cancelText?: ConfirmationModalPropsT["cancelText"];
  content: ConfirmationModalPropsT["content"];
  isConfirmButtonDisabled?: boolean;
  hideConfirmButton?: boolean;
}) => void;

export function useUpdateConfirmationModal() {
  const [modalProps, setModalProps] = useState<ConfirmationModalPropsT | null>(
    null,
  );

  const popConfirmationModal: PopConfirmationModalT = ({
    onConfirm,
    onCancel,
    content,
    confirmText,
    cancelText,
    title,
    isConfirmButtonDisabled = false,
    hideConfirmButton = false,
  }) => {
    setModalProps({
      content,
      showModal: true,
      title: title || "Confirm update",
      confirmText: confirmText || "Confirm",
      cancelText: cancelText || "Cancel",
      isConfirmButtonDisabled,
      hideConfirmButton,
      onConfirm: () => {
        onConfirm();
        setModalProps(null);
      },
      onCancel: () => {
        if (onCancel) {
          onCancel();
        }
        setModalProps(null);
      },
    });
  };

  return {
    popConfirmationModal,
    confirmationModalProps: modalProps,
    ConfirmationModal,
    dismissConfirmationModal: () => setModalProps(null),
  };
}

type ConfirmationModalPropsT = {
  showModal: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  content: () => JSX.Element;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  isConfirmButtonDisabled?: boolean;
  hideConfirmButton?: boolean;
};

function ConfirmationModal({
  onConfirm,
  onCancel,
  showModal,
  title,
  content,
  confirmText,
  cancelText,
  isConfirmButtonDisabled,
  hideConfirmButton,
}: ConfirmationModalPropsT) {
  return (
    <>
      {showModal && (
        <Modal onClose={() => onCancel()}>
          <Modal.Header
            onClose={() => {
              onCancel();
            }}
          >
            {title}
          </Modal.Header>
          <Modal.Body roundedBottomCorners={false}>{content()}</Modal.Body>
          <Modal.Footer className="bg-gray-700">
            <div className="flex justify-between w-full">
              <Button color="darknavy" onClick={() => onCancel()}>
                {cancelText}
              </Button>
              {!hideConfirmButton && (
                <Button
                  color="warning"
                  disabled={isConfirmButtonDisabled}
                  onClick={() => onConfirm()}
                >
                  {confirmText}
                </Button>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
