import { FaFile, FaXmark } from "react-icons/fa6";

export const FileToSend: React.FC<{
  file: File;
  onRemove: () => void;
}> = ({ file, onRemove }) => {
  return (
    <div className="inline-block max-w-[16rem]">
      <div className="flex items-center m-1 bg-indigo-400 rounded text-forteCharcoal-100">
        <span className="p-1 whitespace-pre-wrap leading-tight break-all">
          <FaFile />
        </span>
        <span className="p-1 leading-tight break-all whitespace-nowrap overflow-hidden truncate text-xs">
          {file.name}
        </span>
        <span
          className="p-1 hover:cursor-pointer hover:text-forteCharcoal-60"
          onClick={onRemove}
        >
          <FaXmark />
        </span>
      </div>
    </div>
  );
};
