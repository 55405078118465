/* eslint-disable max-lines */
import React, {
  type PropsWithChildren,
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { FaCheck, FaCircleInfo, FaTriangleExclamation } from "react-icons/fa6";

import { clsx } from "clsx";
import { Button, Progress, Spinner, Toast } from "flowbite-react";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import { useCountdown } from "usehooks-ts";
import { v4 as uuidv4 } from "uuid";

import { StyledText } from "@riffs/StyledText";
import { typecheckExhaustiveNeverCase } from "@util/helper";

import { ILEError } from "./ILE/ILEError";
import { ExternalLink } from "./_shared/ExternalLink";

type ToastContextData = {
  deleteNotification: (notificationId: number | string) => void;
  notifications: ToastNotification[];
  showToast: (toastType: ShowToastConfigT) => void;
  upsertNotification: (
    notification: Omit<ToastNotification, "id"> &
      Partial<Pick<ToastNotification, "id">>,
    repetitionAllowed?: boolean,
  ) => void;
};

type NotificationPositionT =
  | "top-center"
  | "top-left"
  | "bottom-center"
  | "bottom-left"
  | "bottom-right"
  | "top-right";

export enum ToastName {
  ANOTHER_USER_ALREADY_SCREENSHARING,
  SCREEN_SHARE_DENIED,

  NOISE_SUPPRESSION_ON,

  EXPERIENCING_AUDIO_ISSUES,
  EXPERIENCING_VIDEO_ISSUES,

  RECORDING_IS_ON,
  RECORDING_IS_OFF,
  RECORDING_ONLY_TEACHER_CAN_STOP,

  DAILY_FAILED_TO_LOAD,

  STUDENT_IN_WAITING_ROOM,
  STUDENT_LEFT_WAITING_ROOM,

  NEED_MICROPHONE_PERMISSIONS,
  NEED_SPEAKER_PERMISSIONS,
  NEED_VIDEO_PERMISSIONS,

  ILE_PROMPT_TO_SCHEDULE_ONGOING_LESSONS,
}

type ShowToastConfigT =
  | { name: ToastName.ANOTHER_USER_ALREADY_SCREENSHARING }
  | { name: ToastName.SCREEN_SHARE_DENIED }
  | { name: ToastName.NOISE_SUPPRESSION_ON }
  | { name: ToastName.EXPERIENCING_AUDIO_ISSUES }
  | { name: ToastName.EXPERIENCING_VIDEO_ISSUES }
  | { name: ToastName.RECORDING_IS_ON }
  | { name: ToastName.RECORDING_IS_OFF }
  | { name: ToastName.RECORDING_ONLY_TEACHER_CAN_STOP }
  | { name: ToastName.DAILY_FAILED_TO_LOAD }
  | { name: ToastName.ILE_PROMPT_TO_SCHEDULE_ONGOING_LESSONS }
  | {
      formattedLearnerName: string;
      name: ToastName.STUDENT_IN_WAITING_ROOM;
      onAdmitStudent: () => Promise<void>;
    }
  | {
      formattedLearnerName: string;
      name: ToastName.STUDENT_LEFT_WAITING_ROOM;
    }
  | { name: ToastName.NEED_MICROPHONE_PERMISSIONS }
  | { name: ToastName.NEED_SPEAKER_PERMISSIONS }
  | { name: ToastName.NEED_VIDEO_PERMISSIONS };

type ToastNotification = {
  autoDismissSpeed?: "slow" | "medium" | "fast" | "nearInstant";
  displayType?: "info" | "alert" | "error" | "success" | "spinner";
  id: ToastName | string;
  isAutoDismissEnabled?: boolean;
  isDismissibleByUser?: boolean;
  message: string | ReactNode;
  position?: NotificationPositionT;
  showIcon?: boolean;
};

const ToastContext = createContext<ToastContextData>(
  null as unknown as ToastContextData,
);

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }: PropsWithChildren) => {
  const [notifications, setNotifications] = useState<ToastNotification[]>([]);

  const upsertNotification = (
    notificationToUpsert: Omit<ToastNotification, "id"> &
      Partial<Pick<ToastNotification, "id">>,
  ) => {
    setNotifications((previousNotifications) => {
      const id =
        notificationToUpsert.id === undefined
          ? uuidv4()
          : notificationToUpsert.id;

      const didFindNotification = previousNotifications.some(
        (previousNotif) => previousNotif.id === id,
      );

      if (didFindNotification) {
        return previousNotifications.map((existingNotification) => {
          if (existingNotification.id === id) {
            return { ...existingNotification, ...notificationToUpsert };
          }

          return existingNotification;
        });
      } else {
        return [
          ...previousNotifications,
          {
            ...notificationToUpsert,
            id,
          },
        ];
      }
    });
  };

  const deleteNotification = (notificationId: string) => {
    setNotifications((previousNotifications) =>
      previousNotifications.filter(
        ({ id }) => id !== undefined && id !== notificationId,
      ),
    );
  };

  function showToast(toast: ShowToastConfigT) {
    if (toast.name === ToastName.ANOTHER_USER_ALREADY_SCREENSHARING) {
      upsertNotification({
        message: "Another user is already sharing the screen.",
        displayType: "error",
        position: "top-center",
        id: ToastName.ANOTHER_USER_ALREADY_SCREENSHARING,
      });
    } else if (toast.name === ToastName.SCREEN_SHARE_DENIED) {
      upsertNotification({
        message: (
          <p>
            Please give Chrome screen recording permission in order to share
            your screen. You will have to restart Chrome once complete. Click{" "}
            <ExternalLink href="https://fortelessons.com/permissions-guide">
              here
            </ExternalLink>{" "}
            for detailed instructions.
          </p>
        ),
        displayType: "error",
        position: "top-center",
        id: ToastName.SCREEN_SHARE_DENIED,
      });
    } else if (toast.name === ToastName.NOISE_SUPPRESSION_ON) {
      upsertNotification({
        message:
          "Noise Suppression is turned on. You can manually turn it off in the settings menu or by wearing headphones.",
        position: "top-center",
        id: ToastName.NOISE_SUPPRESSION_ON,
        autoDismissSpeed: "slow",
      });
    } else if (toast.name === ToastName.EXPERIENCING_AUDIO_ISSUES) {
      upsertNotification({
        message:
          "If you are experiencing audio issues, please re-select your preferred microphone in the settings menu.",
        displayType: "error",
        position: "top-center",
        id: ToastName.EXPERIENCING_AUDIO_ISSUES,
        autoDismissSpeed: "slow",
      });
    } else if (toast.name === ToastName.EXPERIENCING_VIDEO_ISSUES) {
      upsertNotification({
        message:
          "If you are experiencing video issues, please re-select your preferred camera in the settings menu.",
        displayType: "error",
        position: "top-center",
        autoDismissSpeed: "slow",
        id: ToastName.EXPERIENCING_VIDEO_ISSUES,
      });
    } else if (toast.name === ToastName.RECORDING_ONLY_TEACHER_CAN_STOP) {
      upsertNotification({
        message: "Only the teacher can stop recording.",
        displayType: "error",
        position: "top-center",
        id: ToastName.RECORDING_ONLY_TEACHER_CAN_STOP,
      });
    } else if (
      toast.name === ToastName.RECORDING_IS_ON ||
      toast.name === ToastName.RECORDING_IS_OFF
    ) {
      upsertNotification({
        message: (
          <>
            Recording is&nbsp;
            <strong>
              {toast.name === ToastName.RECORDING_IS_ON ? "ON" : "OFF"}
            </strong>
          </>
        ),
        position: "top-center",
        isAutoDismissEnabled: false,
        id: "recording-status",
      });
    } else if (toast.name === ToastName.STUDENT_IN_WAITING_ROOM) {
      upsertNotification({
        message: (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <AdmitStudentIntoWaitingRoomToast
            formattedLearnerName={toast.formattedLearnerName}
            // eslint-disable-next-line react/jsx-handler-names
            onAdmitStudent={toast.onAdmitStudent}
          />
        ),
        displayType: "info",
        showIcon: false,
        isAutoDismissEnabled: false,
        position: "bottom-center",
        isDismissibleByUser: false,
        id: ToastName.STUDENT_IN_WAITING_ROOM,
      });
    } else if (toast.name === ToastName.STUDENT_LEFT_WAITING_ROOM) {
      upsertNotification({
        message: `${toast.formattedLearnerName} has left the waiting room.`,
        displayType: "info",
        isAutoDismissEnabled: false,
        position: "top-center",
        isDismissibleByUser: true,
        id: ToastName.STUDENT_LEFT_WAITING_ROOM,
      });
    } else if (
      toast.name === ToastName.ILE_PROMPT_TO_SCHEDULE_ONGOING_LESSONS
    ) {
      upsertNotification({
        message: (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <ILEPromptToScheduleOngoingLessonsToast />
        ),
        displayType: "info",
        showIcon: false,
        isAutoDismissEnabled: false,
        position: "top-center",
        isDismissibleByUser: true,
        id: ToastName.ILE_PROMPT_TO_SCHEDULE_ONGOING_LESSONS,
      });
    } else if (toast.name === ToastName.DAILY_FAILED_TO_LOAD) {
      upsertNotification({
        message: (
          <StyledText>
            Could not load VideoCall code. Disable any browser extensions, check
            your firewall settings, and/or allow requests to <b>*.daily.co</b>
          </StyledText>
        ),
        displayType: "error",
        position: "top-center",
        isAutoDismissEnabled: false,
        isDismissibleByUser: false,
        id: ToastName.DAILY_FAILED_TO_LOAD,
      });
    } else if (toast.name === ToastName.NEED_MICROPHONE_PERMISSIONS) {
      upsertNotification({
        message: <NeedDevicePermission deviceKind="audioinput" />,
        displayType: "error",
        position: "top-center",
        isAutoDismissEnabled: false,
        isDismissibleByUser: false,
        id: ToastName.NEED_MICROPHONE_PERMISSIONS,
      });
    } else if (toast.name === ToastName.NEED_SPEAKER_PERMISSIONS) {
      upsertNotification({
        message: <NeedDevicePermission deviceKind="audiooutput" />,
        displayType: "error",
        position: "top-center",
        isAutoDismissEnabled: false,
        isDismissibleByUser: false,
        id: ToastName.NEED_SPEAKER_PERMISSIONS,
      });
    } else if (toast.name === ToastName.NEED_VIDEO_PERMISSIONS) {
      upsertNotification({
        message: <NeedDevicePermission deviceKind="videoinput" />,
        displayType: "error",
        position: "top-center",
        isAutoDismissEnabled: false,
        isDismissibleByUser: false,
        id: ToastName.NEED_VIDEO_PERMISSIONS,
      });
    } else {
      typecheckExhaustiveNeverCase(toast);
    }
  }

  return (
    <ToastContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        notifications,
        upsertNotification,
        deleteNotification,
        showToast,
      }}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      {typeof window !== "undefined" && <ToastList />}
      {children}
    </ToastContext.Provider>
  );
};

const positionLookup = {
  "top-left": { top: "0px", left: "0px" },
  "top-right": { top: "0px", right: "0px" },
  "bottom-left": { bottom: "0px", left: "0px" },
  "bottom-right": { bottom: "0px", right: "0px" },
  "bottom-center": {
    bottom: "100px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  "top-center": { top: "0px", left: "50%", transform: "translateX(-50%)" },
};

const iconLookup = {
  info: <FaCircleInfo width={24} />,
  alert: <FaCircleInfo width={24} />,
  error: <FaTriangleExclamation width={24} />,
  success: <FaCheck width={24} />,
  spinner: <Spinner />,
};

const ToastList: React.FC = () => {
  const { notifications } = useToast();

  const notificationsByPosition = groupBy(
    notifications,
    (notification) => notification.position || "top-left",
  );
  if (notificationsByPosition["bottom-center"]) {
    notificationsByPosition["bottom-center"] = uniqWith(
      notificationsByPosition["bottom-center"],
      isEqual,
    );
  }

  return createPortal(
    <>
      {Object.keys(notificationsByPosition).map(
        (position: NotificationPositionT) => {
          const notificationsForPosition = notificationsByPosition[position];
          if (notificationsForPosition.length === 0) {
            return null;
          }

          return (
            <div
              key={position}
              className="fixed z-30 flex flex-col gap-2 p-2"
              style={positionLookup[position]}
            >
              {notificationsForPosition.map((notification) => {
                return (
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  <ForteToast key={uuidv4()} notification={notification} />
                );
              })}
            </div>
          );
        },
      )}
    </>,
    document.body,
  );
};

const autoDismissIntervalLookup = {
  slow: 60,
  medium: 35,
  fast: 20,
  nearInstant: 10,
};

const ForteToast = ({ notification }: { notification: ToastNotification }) => {
  const {
    displayType,
    isAutoDismissEnabled = true,
    isDismissibleByUser = true,
    showIcon = true,
  } = notification;

  const { deleteNotification } = useToast();

  const [count, { startCountdown, stopCountdown }] = useCountdown({
    // totalTimeInSeconds = countStart * intervalMs
    countStart: 100,
    intervalMs:
      autoDismissIntervalLookup[notification.autoDismissSpeed || "medium"],
  });

  useEffect(() => {
    if (isAutoDismissEnabled) {
      startCountdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (count <= 0) {
      stopCountdown();
      deleteNotification(notification.id);
    }
  }, [count, deleteNotification, notification, stopCountdown]);

  return (
    <Toast
      className={clsx(
        displayType === "error" && "dark:bg-red-950 dark:text-white",
        "min-w-56",
      )}
    >
      <div className="flex flex-grow flex-col">
        <div className="inline-flex flex-grow items-center">
          {showIcon && (
            <div className="flex-shrink-0 w-6">
              {iconLookup[displayType || "info"]}
            </div>
          )}

          <div
            className={clsx(
              "flex flex-grow items-center justify-center",
              "text-sm font-normal",
              showIcon && "ml-4",
            )}
          >
            {notification.message}
          </div>

          {isDismissibleByUser && (
            <Toast.Toggle onClick={() => deleteNotification(notification.id)} />
          )}
        </div>

        {isAutoDismissEnabled && (
          <Progress
            style={{ transform: "scaleX(-1)" }}
            className="mt-2"
            size="sm"
            color="dark"
            progress={count}
          />
        )}
      </div>
    </Toast>
  );
};

const ILEPromptToScheduleOngoingLessonsToast = () => {
  return (
    <div className="">
      On Forte, teachers schedule the lessons with their students.
      <br />
      <br />
      Schedule the next lesson with your student and keep the momentum going!
      <br />
      <br />
      Read more:{" "}
      <ExternalLink
        href="https://help.fortelessons.com/helpcenter/how-do-i-add-lessons-to-my-teaching-schedule"
        includeIcon
      >
        Adding lessons to my Forte teaching Schedule
      </ExternalLink>
    </div>
  );
};

const AdmitStudentIntoWaitingRoomToast: React.FC<{
  formattedLearnerName: string;
  onAdmitStudent: () => Promise<void>;
}> = ({ formattedLearnerName, onAdmitStudent }) => {
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);
    try {
      await new Promise((resolve) => {
        setTimeout(resolve, 200);
      }); // unnecessary delay to make sure the fun animation has enough time

      await onAdmitStudent();
    } catch {
      setLoading(false);
    } finally {
      // noop; we stay in loading===true until the mutation causes twilio datasync to send us an update which tells us the student has been admitted
    }
  };

  return (
    <>
      <div className="flex flex-grow mr-1 text-sm font-normal whitespace-pre-wrap">
        {formattedLearnerName} is in the waiting room.
      </div>

      <div className="flex items-end justify-end w-36 basis-36">
        <Button
          color="neon"
          onClick={onClick}
          isProcessing={loading}
          disabled={loading}
          className=""
        >
          Admit
        </Button>
      </div>
    </>
  );
};

const getDeviceLabel = (deviceKind: MediaDeviceKind) => {
  if (deviceKind === "videoinput") {
    return "Camera";
  } else if (deviceKind === "audioinput") {
    return "Microphone";
  } else if (deviceKind === "audiooutput") {
    return "Speakers";
  }

  return "Device";
};

function NeedDevicePermission({ deviceKind }: { deviceKind: MediaDeviceKind }) {
  const [isShowingILEError, setIsShowingILEError] = useState(false);

  const error = new Error();

  error.name = "NotAllowedError"; // pretending to be the native error that represents insuffiient permissions

  return (
    <>
      <div className="text-md dark:text-red-500 text-red-500">
        You need to grant your browser permission to access your{" "}
        {getDeviceLabel(deviceKind)}
        <Button onClick={() => setIsShowingILEError(true)}>Learn more</Button>
      </div>

      {isShowingILEError && (
        <ILEError
          error={error}
          redirect={false}
          onClose={() => setIsShowingILEError(false)}
        />
      )}
    </>
  );
}
