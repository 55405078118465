import { type Dispatch, type FormEvent, type SetStateAction } from "react";
import { isChrome, isMobile, isSafari } from "react-device-detect";
import {
  FaPaperPlane,
  FaPaperclip,
  FaTriangleExclamation,
} from "react-icons/fa6";
import TextareaAutosize from "react-textarea-autosize";

import { type Media, type Message } from "@twilio/conversations";
import { RoleTypeEnum } from "constants/role";
import { twMerge } from "tailwind-merge";

import { useAuthedViewer } from "@components/AuthedViewerProvider";
import { ChatMessages } from "@components/Chat/ChatMessages";

import { FileToSend } from "../FileToSend";

export const AppChat = ({
  chatScroll,
  downloadHandler,
  downloadingFileIds,
  filesToSend,
  handleSendMessage,
  isDisabled,
  loadPreviousMessage,
  loading,
  maybeRenderDate,
  message,
  messageList,
  setFilesToSend,
  setMessage,
  shareFile,
  uploadingMessageIndexes,
}: {
  chatScroll: React.MutableRefObject<HTMLDivElement | null>;
  downloadHandler: (media: Media) => Promise<void>;
  downloadingFileIds: string[];
  filesToSend: File[];
  handleSendMessage: (event: FormEvent | KeyboardEvent) => Promise<void>;
  isDisabled: boolean;
  loadPreviousMessage: (e: FixMe) => Promise<void>;
  loading: boolean;
  maybeRenderDate: (date: Date | null) => JSX.Element | null;
  message: string;
  messageList: Message[];
  setFilesToSend: Dispatch<SetStateAction<File[]>>;
  setMessage: Dispatch<SetStateAction<string>>;
  shareFile: (files: FileList) => Promise<void>;
  uploadingMessageIndexes: number[];
}) => {
  const { currentRole } = useAuthedViewer();
  const isViewerTeacher = currentRole.type === RoleTypeEnum.TEACHER;

  return (
    <>
      <div
        ref={chatScroll}
        className={twMerge(
          "overflow-auto flex flex-col flex-grow",
          filesToSend.length > 0 ? "pb-12" : "pb-2",
        )}
        onScroll={loadPreviousMessage}
      >
        <ChatMessages
          isILE={false}
          loading={loading}
          messageList={messageList}
          maybeRenderDate={maybeRenderDate}
          downloadingFileIds={downloadingFileIds}
          downloadHandler={downloadHandler}
          uploadingMessageIndexes={uploadingMessageIndexes}
        />
      </div>

      <div
        className={twMerge(
          "px-2 py-1 border-t border-gray-700",
          isMobile && isSafari && "mb-20",
          isMobile && isChrome && "mb-10",
        )}
      >
        <div className="overflow-auto max-h-32">
          {filesToSend.map((obj) => {
            return (
              <FileToSend
                key={obj.name}
                file={obj}
                onRemove={() => {
                  setFilesToSend(filesToSend.filter((file) => file !== obj));
                }}
              />
            );
          })}
        </div>

        <TextareaAutosize
          id="chat"
          value={message}
          autoComplete="off"
          placeholder="Type your message"
          className="mt-1 resize-none block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 dark:text-gray-200 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm"
          minRows={1}
          maxRows={7}
          disabled={isDisabled}
          onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault();
              handleSendMessage(event);
            }
          }}
          onChange={(event) => setMessage(event.currentTarget.value)}
        />
        <div className="flex justify-end items-center text-gray-200">
          <div className="flex items-center gap-2 text-gray-500">
            {isDisabled && !isViewerTeacher && (
              <>
                <FaTriangleExclamation /> Chat was disabled by this teacher.
              </>
            )}
            {isDisabled && isViewerTeacher && (
              <>
                <FaTriangleExclamation /> Chat is currently disabled for this
                student.
              </>
            )}
          </div>
          <label
            className="ltr:right-0 rtl:left-0 cursor-pointer px-2 py-4"
            htmlFor="button-file"
          >
            <input
              className="hidden"
              id="button-file"
              type="file"
              onChange={(event) => {
                if (event.target.files) {
                  shareFile(event.target.files);
                }
              }}
              multiple
            />

            <FaPaperclip />
          </label>

          <button
            type="button"
            className="p-4 px-2"
            onClick={handleSendMessage}
            disabled={isDisabled}
          >
            <FaPaperPlane />
          </button>
        </div>
      </div>
    </>
  );
};
